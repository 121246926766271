<template>
	<div>

        <AddCategory v-if='show_add_category' @close='show_add_category = false;' @added="added_category" />
        <EditCategory v-if='show_edit_category' @close='show_edit_category = false;' @edited="update_category" :item="action_item" />
        <DeleteCategory v-if='show_delete_category' @close='show_delete_category = false;' @edited="deleted_category" :item="action_item" />
        <AddDescription v-if='show_add_description' @close='show_add_description = false;' @added="added_description" />
        <EditDescription v-if='show_edit_description' @close='show_edit_description = false;' @edited="update_description" :item="action_item" />
        <DeleteDescription v-if='show_delete_description' @close='show_delete_description = false;' @edited="deleted_description" :item="action_item" />
        <AddSize v-if='show_add_size' @close='show_add_size = false;' @added="added_size" />
        <EditSize v-if='show_edit_size' @close='show_edit_size = false;' @edited="update_size" :item="action_item" />
        <DeleteSize v-if='show_delete_size' @close='show_delete_size = false;' @edited="deleted_size" :item="action_item" />
        <AddConnection v-if='show_add_connection' @close='show_add_connection = false;' @added="added_connection" />
        <EditConnection v-if='show_edit_connection' @close='show_edit_connection = false;' @edited="update_connection" :item="action_item" />
        <DeleteConnection v-if='show_delete_connection' @close='show_delete_connection = false;' @edited="deleted_connection" :item="action_item" />

        <div class="container-fluid">
            <div class="card mt-3">
                <div class="card-body">
                    <h4 class="card-title">Category Configuration</h4>
                    <div class="actions">
                        <button class="btn btn-primary btn--icon-text ml-3 mr-3" v-tooltip="'Add a Configuration'" @click='add_category()'><i class="zwicon-plus"></i> Add Category</button>
                    </div>

                    <div class="table-responsive data-table table-sm table-striped">
                        <div id="data-table_wrapper" class="dataTables_wrapper no-footer">
                            <table id="data-table" class="table dataTable no-footer" role="grid" aria-describedby="data-table_info">
                            <thead class="table-dark">
                                <tr role="row">
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">ID</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">Category</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in category_list.list" v-bind:key="item">
                                <td>
                                    {{item.configuration_id || ''}}     
                                </td>
                                <td>
                                    {{item.name || ''}}
                                </td>
                                <td style='text-align: right; position: relative;' v-if='item.configuration_id > 0' @click='toggle_v_dropdown(item.configuration_id+"-"+item.name)'>
                                    <button class="btn btn-primary btn-sm" v-tooltip="'Toggle Menu'"><i class="zwicon-more-h"></i>&nbsp;Actions</button>
                                    <div class="dropdown-menu dropdown-menu-right" v-if='active_item.toString() == item.configuration_id.toString()+"-"+item.name.toString()' v-click-outside="toggle_v_dropdown"> <!--  v-if='user_dd["x"+user_item.user_id]' -->
                                        <a class="dropdown-item" @click='edit_category(item)'>Edit</a>
                                        <a class="dropdown-item" @click='delete_category(item)'>Delete</a>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Description Configuration</h4>
                    <div class="actions">
                        <button class="btn btn-primary btn--icon-text ml-3 mr-3" v-tooltip="'Add a Configuration'" @click='add_description()'><i class="zwicon-plus"></i> Add Description</button>
                    </div>

                    <div class="table-responsive data-table table-sm table-striped">
                        <div id="data-table_wrapper" class="dataTables_wrapper no-footer">
                            <table id="data-table" class="table dataTable no-footer" role="grid" aria-describedby="data-table_info">
                            <thead class="table-dark">
                                <tr role="row">
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">ID</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">Description</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">Category</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in description_list.list" v-bind:key="item">
                                <td>
                                    {{item.configuration_id || ''}}     
                                </td>
                                <td>
                                    {{item.name || '-'}}     
                                </td>
                                <td>
                                    {{item.parent_label}}
                                </td>
                                
                                
                                
                                <td style='text-align: right; position: relative;' v-if='item.configuration_id > 0' @click='toggle_v_dropdown(item.configuration_id+"-"+item.name)'>
                                    <button class="btn btn-primary btn-sm" v-tooltip="'Toggle User Menu'"><i class="zwicon-more-h"></i>&nbsp;Actions</button>
                                    <div class="dropdown-menu dropdown-menu-right" v-if='active_item.toString() == item.configuration_id.toString()+"-"+item.name.toString()' v-click-outside="toggle_v_dropdown"> <!--  v-if='user_dd["x"+user_item.user_id]' -->
                                        <a class="dropdown-item" @click='edit_description(item)'>Edit</a>
                                        <a class="dropdown-item" @click='delete_description(item)'>Delete</a>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Size Configuration</h4>
                    <div class="actions">
                        <button class="btn btn-primary btn--icon-text ml-3 mr-3" v-tooltip="'Add a Configuration'" @click='add_size()'><i class="zwicon-plus"></i> Add Size</button>
                    </div>

                    <div class="table-responsive data-table table-sm table-striped">
                        <div id="data-table_wrapper" class="dataTables_wrapper no-footer">
                            <table id="data-table" class="table dataTable no-footer" role="grid" aria-describedby="data-table_info">
                            <thead class="table-dark">
                                <tr role="row">
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">ID</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">Size</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in size_list.list" v-bind:key="item">
                                <td>
                                    {{item.configuration_id || ''}}     
                                </td>
                                <td>
                                    {{item.name || '-'}}     
                                </td>
                                
                                
                                
                                <td style='text-align: right; position: relative;' v-if='item.configuration_id > 0' @click='toggle_v_dropdown(item.configuration_id+"-"+item.name)'>
                                    <button class="btn btn-primary btn-sm" v-tooltip="'Toggle User Menu'"><i class="zwicon-more-h"></i>&nbsp;Actions</button>
                                    <div class="dropdown-menu dropdown-menu-right" v-if='active_item.toString() == item.configuration_id.toString()+"-"+item.name.toString()' v-click-outside="toggle_v_dropdown"> <!--  v-if='user_dd["x"+user_item.user_id]' -->
                                        <a class="dropdown-item" @click='edit_size(item)'>Edit</a>
                                        <a class="dropdown-item" @click='delete_size(item)'>Delete</a>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">Connection Configuration</h4>
                    <div class="actions">
                        <button class="btn btn-primary btn--icon-text ml-3 mr-3" v-tooltip="'Add a Configuration'" @click='add_connection()'><i class="zwicon-plus"></i> Add Connection</button>
                    </div>

                    <div class="table-responsive data-table table-sm table-striped">
                        <div id="data-table_wrapper" class="dataTables_wrapper no-footer">
                            <table id="data-table" class="table dataTable no-footer" role="grid" aria-describedby="data-table_info">
                            <thead class="table-dark">
                                <tr role="row">
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">ID</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">Connection</th>
                                    <th class="sorting" tabindex="0" aria-controls="data-table" rowspan="1" colspan="1">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in connector_list.list" v-bind:key="item">
                                <td>
                                    {{item.configuration_id || ''}}     
                                </td>
                                <td>
                                    {{item.name}}
                                </td>
                                
                                
                                
                                <td style='text-align: right; position: relative;' v-if='item.configuration_id > 0' @click='toggle_v_dropdown(item.configuration_id+"-"+item.name)'>
                                    <button class="btn btn-primary btn-sm" v-tooltip="'Toggle User Menu'"><i class="zwicon-more-h"></i>&nbsp;Actions</button>
                                    <div class="dropdown-menu dropdown-menu-right" v-if='active_item.toString() == item.configuration_id.toString()+"-"+item.name.toString()' v-click-outside="toggle_v_dropdown"> <!--  v-if='user_dd["x"+user_item.user_id]' -->
                                        <a class="dropdown-item" @click='edit_connection(item)'>Edit</a>
                                        <a class="dropdown-item" @click='delete_connection(item)'>Delete</a>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>

	import { mapGetters } from "vuex";
	import store from "@/store";
	import router from "@/router";

	import { LOAD_CATEGORY_LIST, LOAD_DESCRIPTION_LIST, LOAD_SIZE_LIST, LOAD_CONNECTION_LIST } from "@/store/actions.type";

    import AddCategory from '@/views/Config/Modals/AddCategory';
    import EditCategory from '@/views/Config/Modals/EditCategory';
    import DeleteCategory from '@/views/Config/Modals/DeleteCategory';
    import AddDescription from '@/views/Config/Modals/AddDescription';
    import EditDescription from '@/views/Config/Modals/EditDescription';
    import DeleteDescription from '@/views/Config/Modals/DeleteDescription';
    import AddSize from '@/views/Config/Modals/AddSize';
    import EditSize from '@/views/Config/Modals/EditSize';
    import DeleteSize from '@/views/Config/Modals/DeleteSize';
    import AddConnection from '@/views/Config/Modals/AddConnection';
    import EditConnection from '@/views/Config/Modals/EditConnection';
    import DeleteConnection from '@/views/Config/Modals/DeleteConnection';

	export default {
		name: 'Config',
		components: {
            AddCategory,
            EditCategory,
            DeleteCategory,
            AddDescription,
            EditDescription,
            DeleteDescription,
            AddSize,
            EditSize,
            DeleteSize,
            AddConnection,
            EditConnection,
            DeleteConnection,
		},
		props: {
			error: {
				type: String,
				default: "" 
			},
			success: {
				type: String,
				default: ""
			}
		},
        data(){
            return {
                filter_list: false,
                active_item: '',
                show_view: false,
                show_add_category: false,
                show_edit_category: false,
                show_delete_category: false,
                show_add_description: false,
                show_edit_description: false,
                show_delete_description: false,
                show_add_size: false,
                show_edit_size: false,
                show_delete_size: false,
                show_add_connection:false,
                show_edit_connection:false,
                show_delete_connection:false,
                show_edit: false,
                show_filter_modal: false,
                success_msg: '',
				filter_error: '',
                filter: {},
            }
        },
        beforeMount() {
            this.reset_filter_form();
			this.load_list(function(){
                console.log('try to load')
			}, function(){
                console.log('load error')
				router.replace({ name: 'inventory', params: { error: "An error occured while attempting to load inventory list." }});
			});
		},
		computed: {
			...mapGetters(['inventory_list', 'user', 'loading','size_list','category_list','connector_list','description_list'])
		},
        methods: {
            category_name(desc_id){
                let item = this.category_list.list.filter(x => x.id == desc_id);
                if(item.length == 1) return item[0].name;
                else return '???';
            },
            toggle_v_dropdown(id){
                if(this.active_item == id) this.active_item = '';
                else this.active_item = id;
            },
            reset_filter_form(){
				this.filter = {
					page: 0,
					search: '',
					sort_by: 'id',
					sort_dir: 'DESC'
				};
			},
			open_filters(){
				this.show_filter_modal = true;
			},
			close_filters(){
				this.show_filter_modal = false;
			},
            load_list(){
				this.load_category();
                this.load_description();
                this.load_size();
                this.load_connections();
			},
            load_category(){
                store.dispatch(LOAD_CATEGORY_LIST).then(() => {
				}).catch(message => {
					console.log('error: '+message)
				});
            },
            load_description(){
                store.dispatch(LOAD_DESCRIPTION_LIST).then(() => {
				}).catch(message => {
					console.log('error: '+message)
				});
            },
            load_size(){
                store.dispatch(LOAD_SIZE_LIST).then(() => {
				}).catch(message => {
					console.log('error: '+message)
				});
            },
            load_connections(){
                store.dispatch(LOAD_CONNECTION_LIST).then(() => {
				}).catch(message => {
					console.log('error: '+message)
				});
            },
            view_page(page){
				this.filter.page = page;
				this.load_list();
			},
            view_item(item){
				this.action_item = item;
				this.show_view = true;
			},
            edit_item(item){
				this.action_item = item;
				this.show_edit = true;
			},
            add_category(){
				this.action_item = {};
				this.show_add_category = true;
			},
            edit_category(item){
				this.action_item = item;
				this.show_edit_category = true;
			},
            delete_category(item){
				this.action_item = item;
				this.show_delete_category = true;
			},
            add_description(){
				this.action_item = {};
				this.show_add_description = true;
			},
            edit_description(item){
				this.action_item = item;
				this.show_edit_description = true;
			},
            delete_description(item){
				this.action_item = item;
				this.show_delete_description = true;
			},
            add_size(){
				this.action_item = {};
				this.show_add_size = true;
			},
            edit_size(item){
				this.action_item = item;
				this.show_edit_size = true;
			},
            delete_size(item){
				this.action_item = item;
				this.show_delete_size = true;
			},
            add_connection(){
				this.action_item = {};
				this.show_add_connection = true;
			},
            edit_connection(item){
				this.action_item = item;
				this.show_edit_connection = true;
			},
            delete_connection(item){
				this.action_item = item;
				this.show_delete_connection = true;
			},
            update_item(new_item){
                this.show_edit = false;
                this.load_list();       
                new_item;
            },
            added_item(new_item){
                this.show_add = false;
                this.load_list();       
                new_item;
            },

            // add modal closes
            added_category(){
                this.show_add_category = false;
                this.load_category();
            },
            added_size(){
                this.show_add_size = false;
                this.load_size();
            },
            added_description(){
                this.show_add_description = false;
                this.load_description();
            },
            added_connection(){
                this.show_add_connection = false;
                this.load_connections();
            },

            // edit modal closes
            update_category(){
                this.show_edit_category = false;
                this.load_category();
            },
            update_size(){
                this.show_edit_size = false;
                this.load_size();
            },
            update_description(){
                this.show_edit_description = false;
                this.load_description();
            },
            update_connection(){
                this.show_edit_connection = false;
                this.load_connections();
            },
            
            
            // delete modal closes
            deleted_category(){
                this.show_delete_category = false;
                this.load_category();
            },
            deleted_size(){
                this.show_delete_size = false;
                this.load_size();
            },
            deleted_description(){
                this.show_delete_description = false;
                this.load_description();
            },
            deleted_connection(){
                this.show_delete_connection = false;
                this.load_connections();
            }
        }
	}
</script>