<template>
	<!-- Add Config Modal -->
		<div class="modal fade show" id="modal-default" tabindex="-1" style="display: block;" aria-modal="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Add Connection</h5><i class="modal-close zwicon-close pull-right display-4" @click='close'></i>
					</div> 
					<div class="modal-body">
						<table id="data-table" class="table dataTable no-footer" role="grid" aria-describedby="data-table_info">
                            <tbody class="table-dark">
                                <tr role="row">
                                    <th colspan="1"><label for="temp-item-serialno">Connection Name</label></th>
                                    <td colspan="3"> 
                                        <div class="form-group mb-0">
                                            <input id="temp-item-serialno" class="form-control" v-model='temp_item_serial_no'>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
					</div>
					<ErrorMessage :message='error' v-if='error.length > 0' />
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" @click='action'><i class="zwicon-plus"></i> Add Connection</button>
                    </div>
				</div>
			</div>
		</div>
		<!-- End Add Item Modal -->
</template>

<script>
	import PermissionService from "@/common/permission.service";

    import { mapGetters } from "vuex";
	import ErrorMessage from "@/components/General/ErrorMessage";
    import store from "@/store";

	import { ADD_CATEGORY_ENTRY } from "@/store/actions.type";

	export default {
		name: 'AddDescription',
		components: {
			ErrorMessage
		},
		props: {
            item: {},
        },
		data(){
			return {
				error: '',
				user: {
					name: '',
					email: '',
					role: ''
				},

                temp_item_serial_no: '',

				errors: {
					name: '',
					email: '',
					role: ''
				}
			};
		},
        mounted(){
            this.temp_item = this.item;
            
        },
        computed: {
            new_item(){
                return {
                    'type': 'connections',
                    'name': this.temp_item_serial_no
                }
            },
            filtered_description_list(){
                if([null, false, ''].includes(this.temp_item_category)) return [];
                return this.description_list.filter(x => x.parent_id == this.temp_item_category);
            }, 
            ...mapGetters(['inventory_list', 'user', 'loading', 'size_list', 'category_list', 'connector_list', 'description_list'])
        },
		methods: {
			getPermission(category, permission){
				return PermissionService.getPermission(category, permission);
			},
			get_error(key){
				if(this.errors[key] !== undefined) return this.errors[key];
				return '';
			},
			close(){
				this.$emit('close');
			},
			action(){
				store.dispatch(ADD_CATEGORY_ENTRY, this.new_item).then(() => {
                    
                    this.$emit('added',this.new_item);
				}).catch(message => {
					console.log(message);
				}).finally(() => {
					
				});
                
				
			},
		}
	}
</script>