<template>
	<!-- Edit Config Modal -->
		<div class="modal fade show" id="modal-default" tabindex="-1" style="display: block;" aria-modal="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Delete Size</h5><i class="modal-close zwicon-close pull-right display-4" @click='close'></i>
					</div> 
					<div class="modal-body">
						<table id="data-table" class="table dataTable no-footer" role="grid" aria-describedby="data-table_info">
                            <tbody class="table-dark">
                                <tr role="row">
									<td colspan="3">Are you sure you would like to <b>delete</b> the category named <b>{{this.temp_item_name}}</b></td>
                                </tr>
                            </tbody>
                        </table>
					</div>
					<ErrorMessage :message='error' v-if='error.length > 0' />
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" @click='action'><i class="zwicon-plus"></i> Delete Size</button>
                    </div>
				</div>
			</div>
		</div>
		<!-- End Add Item Modal -->
</template>

<script>
	import PermissionService from "@/common/permission.service";

    import { mapGetters } from "vuex";
	import ErrorMessage from "@/components/General/ErrorMessage";
    import store from "@/store";

	import { DELETE_CATEGORY_ENTRY } from "@/store/actions.type";

	export default {
		name: 'DeleteSize',
		components: {
			ErrorMessage
		},
		props: {
            item: {},
        },
		data(){
			return {
				error: '',
                id: '',
				temp_item_name: '',
                temp_item_id: '',
			};
		},
        mounted(){
            this.temp_item_id = this.item.configuration_id;
			this.temp_item_name = this.item.name;
            
        },
        computed: {
            new_item(){
                return {
                    'category_id': this.temp_item_id
                }
            },
            ...mapGetters(['user', 'loading', 'size_list'])
        },
		methods: {
			getPermission(category, permission){
				return PermissionService.getPermission(category, permission);
			},
			get_error(key){
				if(this.errors[key] !== undefined) return this.errors[key];
				return '';
			},
			close(){
				this.$emit('close');
			},
			action(){
				store.dispatch(DELETE_CATEGORY_ENTRY, this.new_item).then(() => {
                    
                    this.$emit('edited',this.new_item);
				}).catch(message => {
					console.log(message);
				}).finally(() => {
					
				});
                
				
			},
		}
	}
</script>